import { useEffect } from 'react';
import useAuthentication from '../hooks/useAuthentication.js';
import LoadingSpinner from '../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../components/common/ErrorMessage.jsx';
import eassistLogo from '../assets/images/eAssistIcon.png';

const LoginPage = () => {
    const { tokens, loading, error, login } = useAuthentication();

    const handleCallbackResponse = async (response) => {
        const googleJwt = response.credential;
        await login(googleJwt);
    };

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id:
                process.env.REACT_APP_ENV === 'production'
                    ? '940639730008-9o1vplaqdt9f59786lcht0ganhhdkb3e.apps.googleusercontent.com'
                    : '940639730008-le4pbtfmvncoe6ike7knql5qc1kvl6em.apps.googleusercontent.com',
            callback: handleCallbackResponse
        });

        google.accounts.id.renderButton(document.getElementById('signInDiv'), {
            theme: 'outline',
            size: 'large'
        });
    }, []);

    /**
     * useEffect hook to reload the page once tokens are received.
     */
    useEffect(() => {
        if (tokens) {
            sessionStorage.setItem('accessToken', tokens.access.token);
            sessionStorage.setItem('refreshToken', tokens.refresh.token);
            window.location.reload();
        }
    }, [tokens]);

    return (
        <>
            <div className="main-header" style={{ justifyContent: 'center' }}>
                <h2>eAssist Email Signatures</h2>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '40em',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                <img
                    src={eassistLogo}
                    alt="eassist logo"
                    style={{ height: '150px', marginBottom: '2em' }}
                />
                <h3 style={{ marginBottom: '2em' }}>
                    Sign in with Google to continue
                </h3>
                <div id="signInDiv" />
            </div>
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
        </>
    );
};

export default LoginPage;
